let association = function ($) {

    /**
     * Run answers
     */
    let traceLine = function () {

        // Responses utilisateur
        let answers = [];

        // Au clique sur un item
        $('body').off('click', '.association .item').on('click', '.association .item', function () {
            updateCheckbox(this);

            let moduleId = $(this).closest('.items[data-module-id]').data('module-id');

            // Réponses utilisateur
            let $inputAnswers = $('.association input.answers-user');
            let $reset = $('#module .association .reset a');

            // Colonne
            let $col = $('.association .col');
            let $colLeft = $('.association .col-left');
            let $colRight = $('.association .col-right');

            // Canvas
            let $canvas = $('.association .canvas');

            // Items
            let $items = $('.association .item');
            let itemsLength = $items.length;
            let itemWith = $items.outerWidth();

            // Content
            let $content = $('.association .content');
            let contentWidth = $content.outerWidth();
            let contentHeight = $content.outerHeight();
            let widthLeft = contentWidth - itemWith;
            let widthRight = (contentWidth - (itemWith * 2)) / 2;

            // Coordonnées des cibles
            let coordLeft = {x: itemWith, y: false};
            let coordRight = {x: widthRight, y: false};

            $items.each(function (k) {
                if ($(this).hasClass('selected')) {
                    let heightItem = $(this).outerHeight();
                    let positionTopTarget = $(this).position().top + (heightItem / 2);

                    if ($(this).closest('.col').hasClass('col-left')) {
                        coordLeft.y = positionTopTarget;
                    } else {
                        coordRight.y = positionTopTarget;
                    }
                }

                if (k === itemsLength - 1) {
                    if (coordLeft.y && coordRight.y) {
                        let html;
                        let canvasId = 'canvas-0';
                        let id = 0;
                        $canvas.each(function (k) {
                            if ($content.find('#canvas-' + k).length !== 0) {
                                id = k + 1;
                                canvasId = 'canvas-' + id;
                            }
                        });
                        html = '<canvas id="' + canvasId + '" class="canvas canvas-1" width="' + contentWidth + '" height="' + contentHeight + '"></canvas>';
                        $(html).appendTo($content);

                        let ctx = document.getElementById(canvasId);
                        if (ctx) {
                            ctx = document.getElementById(canvasId).getContext("2d");
                            ctx.strokeStyle = color_module[(moduleId && moduleId !== '') ? moduleId : 1];
                            ctx.lineWidth = "3";
                            ctx.beginPath();
                            ctx.moveTo(itemWith, coordLeft.y);
                            ctx.lineTo(widthLeft, coordRight.y);
                            ctx.stroke();
                        }

                        let idxLeft = $colLeft.find('.selected').index();
                        let idxRight = $colRight.find('.selected').index();
                        let titleLeft = $colLeft.find('.selected').data('title');
                        let titleRight = $colRight.find('.selected').data('title');

                        if (idxLeft in answers === false) {
                            answers[idxLeft] = {};
                        }

                        answers[idxLeft].idxLeft = idxLeft;
                        answers[idxLeft].titleLeft = titleLeft;

                        answers[idxLeft].idxRight = idxRight;
                        answers[idxLeft].titleRight = titleRight;

                        $inputAnswers.val(JSON.stringify(answers)).trigger('change');

                        // Suppression des class "selected" et ajout de la class "associated"
                        $col.removeClass('selection').find('.selected').addClass('associated').removeClass('selected');
                        $reset.removeClass('disabled');
                        let statusAnswers = true;
                        $items.each(function (k) {
                            if (!$(this).hasClass('associated')) statusAnswers = false;
                            if (k === itemsLength - 1 && statusAnswers) {
                                let buttonAnswers = $('.association .answers a');
                                buttonAnswers.removeClass('disabled');
                                let $next = $body.find('#module > section.main > .content > .next');
                                $next.removeClass('disabled');
                            }
                        });
                    }
                }
            });
        });

        let resizeTimer;
        $(window).on('resize', function (e) {
            let contentWidth = $('.association .content').width();
            let moduleId = $('.association .content').find('.items[data-module-id]').data('module-id');
            if ($('.association').is(':visible') && contentWidth < 792) {
                let $inputAnswers = $('.association .answers-user');
                if ($inputAnswers.val() !== '') {
                    displayLoader(true);
                }

                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(function () {
                    // Réponses utilisateur
                    if ($inputAnswers.val() !== '') {

                        // Colonne
                        let $col = $('.association .col');
                        let $colLeft = $('.association .col-left');
                        let $colRight = $('.association .col-right');

                        // Canvas
                        let $canvas = $('.association .canvas');

                        // Items
                        let $items = $('.association .item');
                        let itemWith = $items.outerWidth();
                        let heightItem = $items.outerHeight();

                        // Content
                        let $content = $('.association .content');
                        let contentWidth = $content.outerWidth();
                        let contentHeight = $content.outerHeight();
                        let widthLeft = $content.width() - itemWith;
                        let widthRight = (contentWidth - (itemWith * 2)) / 2;

                        // Coordonnées des cibles
                        let coordLeft = {x: itemWith, y: false};
                        let coordRight = {x: widthRight, y: false};

                        let canvasId;

                        $canvas.remove(); // Suppression des canvas
                        answers = JSON.parse($inputAnswers.val());

                        var time = 0;
                        $.each(answers, function (k) {
                            setTimeout(function () {
                                if (k in answers && answers[k] != null) {
                                    canvasId = 'canvas-' + k;
                                    let html = '<canvas id="' + canvasId + '" class="canvas canvas-1" width="' + contentWidth + '" height="' + contentHeight + '"></canvas>';
                                    $(html).appendTo($content);
                                    coordLeft.y = $colLeft.find('.item').eq(k).position().top + (heightItem / 2);
                                    coordRight.y = $colRight.find('.item').eq(answers[k].idxRight).position().top + (heightItem / 2);
                                }

                                if (coordLeft.y && coordRight.y) {
                                    let ctx = [];
                                    ctx[k] = document.getElementById(canvasId);
                                    if (ctx[k]) {
                                        ctx[k] = document.getElementById(canvasId).getContext("2d");
                                        ctx[k].lineWidth = "3";
                                        ctx[k].strokeStyle = color_module[(moduleId && moduleId !== '') ? moduleId : 1];
                                        ctx[k].beginPath();
                                        ctx[k].moveTo(itemWith, coordLeft.y);
                                        ctx[k].lineTo(widthLeft, coordRight.y);
                                        ctx[k].stroke();
                                    }
                                }
                                if (k === answers.length - 1) {
                                    displayLoader(false);
                                }
                            }, time);
                            time += 100;
                        });
                    }
                }, 250);
            }
        });
    };

    let updateCheckbox = function (item) {

        let $item = $(item);
        let $col = $item.closest('.col');
        let $items = $col.find('.item');
        let associationMulti = $body.find('.association-multi').length !== 0 ? true : false;

        if (associationMulti) {

            let itemsLength = $body.find('.col-left').find('.item').length;
            let itemsAssociatedLength = $body.find('.col-left').find('.item.associated').length;

            if (itemsLength === itemsAssociatedLength) return false;

            if (!$col.hasClass('selection')) {
                $item.closest('.col').addClass('selection');
            } else {
                $item.closest('.col').removeClass('selection');
            }

            if ((!$item.hasClass('selected') && !$item.hasClass('associated') && !$item.closest('.col-right').length > 0)
                || (!$item.hasClass('selected')  && !$item.closest('.col-left').length > 0)) {
                $items.removeClass('selected');
                $item.addClass('selected');
            } else {
                $items.removeClass('selected');
            }
        } else {
            if (!$col.hasClass('selection')) {
                $item.closest('.col').addClass('selection');
            } else {
                $item.closest('.col').removeClass('selection');
            }

            if (!$item.hasClass('selected') && !$item.hasClass('associated')) {
                $items.removeClass('selected');
                $item.addClass('selected');
            } else {
                $items.removeClass('selected');
            }
        }

    };

    /**
     * Affichage du loader
     * @param status boolean
     */
    let displayLoader = function (status) {
        let $loader = $('.association .loader');
        if (status) {
            $loader.css('display', 'flex');
        } else {
            setTimeout(function () {
                $loader.hide();
            }, 100)
        }
    };

    /**
     * Run answers
     */
    let runAnswers = function () {
        $('body').on('click', '#module .association .answers a', function (e) {
            e.preventDefault();
            let _this = this;

            let $items = $('.association .item');
            let itemsLength = $items.length;
            $items.each(function (k) {
                if (!$(this).hasClass('associated')) return false;
                if (k === itemsLength - 1) {
                    $(_this).fadeOut(function () {
                        $(_this).next().fadeIn();
                    });
                }
            });
        });
    };

    /**
     * Reset
     */
    let reset = function () {
        $('body').on('click', '#module .association .reset a', function (e) {
            e.preventDefault();
            let _this = this;

            if ($(_this).hasClass('disabled')) return false;

            let $canvas = $('#module .association .canvas');
            let $items = $('#module .association .item');
            let $col = $('#module .association .col');
            let $answer = $('#module .association .answers a');

            $canvas.remove();
            $items.removeClass('selected').removeClass('associated');
            $col.removeClass('selection');

            $(_this).addClass('disabled');
            $answer.addClass('disabled');
        });
    };

    return {
        init: function () {
            traceLine();
            runAnswers();
            reset();
        }
    };
}(jQuery);

$(document).ready(function () {
    association.init();
});